
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { SelectOption } from './model';

@Component({
  name: 'Select',
})
export default class Select extends Vue {
  private innerValue: string;

  @Prop({ required: true }) options: SelectOption[];
  @Prop({ default: null }) value: string;
  @Prop({ default: null }) label: string;
  @Prop({ default: 'left' }) textAlign: string;
  @Prop({ default: false }) error: boolean;
  @Prop({ default: false }) compact: boolean;

  data() {
    return {
      innerValue: this.value || this.options[0].value,
    };
  }

  @Emit('change')
  onChange(e: Event & { target: HTMLSelectElement }) {
    const index: number = parseInt(e.target.value);
    const option = this.options[index];
    this.innerValue = option.value;
    return option.value;
  }
}
